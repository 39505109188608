import axios from 'axios';

const api = {
  sendContactEmail
};

export default api;

const URL = '/api/email';

function sendContactEmail(data){
  return axios.post(`${URL}/contact`, data);
}