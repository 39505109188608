import classNames from 'classnames';
import {motion} from 'framer-motion';
import {useInView} from 'react-intersection-observer';

const listMotion = {
  initial: {
    opacity: 0,
    transition: {
      when: "afterChildren"
    }
  },
  animate: {
    opacity: 1,
    transition:{
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const itemMotion = {
  initial: {scale: 0},
  animate: {scale: 1}
};

const defaultGridStyle = "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3";

export default function GridList({items, keyExpr, children, gridClass=defaultGridStyle}) {
  const [ref, inView] = useInView({triggerOnce: true, rootMargin: '-150px 0px'});

  return (
    !items
    ? null
    : <motion.ul ref={ref} initial="initial" animate={inView ? "animate" : "initial"} variants={listMotion}>
      <div className={classNames(`grid`, gridClass)}>
      {
        items.map((item, idx) => <motion.li variants={itemMotion} key={keyExpr ? item[keyExpr] : idx}>{children(item)}</motion.li>)
      }
      </div>
    </motion.ul>
  )
}
