import classNames from "classnames";
import Button from "./Button";

export default function ButtonDefault({children, className, ...props}) {
  return (
    <Button 
      className={classNames("bg-white hover:bg-black text-black hover:text-white border border-black", className)} 
      {...props}
    >
      {children}
    </Button>
  );
}
