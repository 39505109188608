import classNames from 'classnames';
import css from './Checkbox.module.css';

export default function Checkbox({id, name, label, className, ...otherProps}) {
  return (
    <label className={classNames(css.checkbox, className)}>
      <span className={css.checkbox__input}>
        <input type="checkbox" id={id} name={id || name} {...otherProps}/>
        <span className={css.checkbox__control}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' aria-hidden="true" focusable="false"><path fill='none' stroke='currentColor' strokeWidth='6' d='M1.73 12.91l6.37 6.37L22.79 4.59' /></svg>
        </span> 
      </span>
      <span className={css.checkbox__label}>{label}</span>
    </label>
  )
}
