import classNames from "classnames";

export default function Button({children, className, ...props}) {
  return (
    <button 
      className={classNames("px-8 py-3 font-semibold uppercase text-sm", className)} 
      {...props}
    >
      {children}
    </button>
  );
}
