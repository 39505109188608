import React from 'react';
import classNames from "classnames";

// const Input = React.forwardRef(({className, ...props}, ref) => (
//   <input ref={ref} className={classNames("control", className)} {...props} />
// ));

// export default Input;

export default function Input({className, ...props}) {
  return (
    <input className={classNames("control", className)} {...props} />
  )
}
