import { useState, useEffect } from 'react';
import {useFormik} from 'formik';
import { reduce, keys, prop } from 'ramda';
import * as Yup from 'yup';

import emailApi from '../api/email-api';
import Grid from './Grid';
import Row from './Row';
import Input from './Input';
import {FieldInput, FieldCheckboxList, FieldCheckbox, FieldTextarea} from './FieldControls';
import ButtonDefault from './ButtonDefault';
import Center from './Center';

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  interestedIns: [],
  message: "",
  howToHearUs: ""
};

const validationSchema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().required('Required'),
  interestedIns: Yup.array('Required'),
  message: Yup.string(),
  howToHearUs: Yup.string()
});

const ID_INTERESTED_INS = "interestedIns";
const ID_BRANDING = "branding";
const ID_CARD = "card";
const ID_FRAME = "frame";
const ID_ETC = "interestedIn-etc";

const fields = [
  {id: "firstName", name: "firstName", label: "Name *", placeholder:"First Name"},
  {id: "lastName", name: "lastName", label: "", placeholder:"Last Name" },
  {id: "email", name: "email", label: "Email Address *"},
  {
    id: ID_INTERESTED_INS, name:"interestedIns", label: "What are you interested in? *", 
    items:[
      {id: ID_BRANDING, value:"Branding (drawaing logo)", label: "Branding (drawaing logo)"}, 
      {id: ID_CARD, value: "Custom Card", label: "Custom Card"},
      {id: ID_FRAME, value: "Custom Frame", label: "Custom Frame"},
    ]
  },
  {id: ID_ETC, label: "Etc"},
  {id: "interestedIn-etc-text"},
  {id: "message", name: "message", label: "Message"},
  {id: "howToHearUs", name: "howToHearUs", label: "How did you hear about us?"},
];

const fieldInfo = reduce((acc, cur) => {
  acc[cur.id] = cur;
  return acc;
}, {}, fields);

const interedInIds = fieldInfo[ID_INTERESTED_INS].items.map(prop('id')).concat(ID_ETC);

const buildFormValues = (values, etc) => reduce((acc, key) => {
  if(interedInIds.find(d => d === key)){
    acc[ID_INTERESTED_INS] = acc[ID_INTERESTED_INS].concat(key === ID_ETC ? etc : values[key]);
  } else {
    acc[key] = values[key];
  }
  return acc;
}, {}, keys(values));

export default function ContactUsForm() {
  const [etc, setEtc] = useState('');

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    if(!formik.values[ID_ETC]){
      setEtc('');
    }
  }, [formik.values]);

  function handleSubmit(values, {resetForm}){
    const finalValues = buildFormValues(values, etc);
    emailApi.sendContactEmail(finalValues)
      .then(res => {
        const isSuccess = res.data === "success";
        const message = isSuccess
          ? "Your message was successfully sent to us. We will contact you ASAP, thank you."
          : "Sorry, we have technical difficulties, please try it later."
          window.alert(message);

        if(isSuccess){
          resetForm();
        }
      });
  };

  const handleChangeEtc = e => setEtc(e.target.value);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid>
        <FieldInput field={fieldInfo['firstName']} formik={formik} />
        <FieldInput field={fieldInfo['lastName']} formik={formik} />
      </Grid>
      <FieldInput field={fieldInfo['email']} formik={formik} />
      <FieldCheckboxList field={fieldInfo['interestedIns']} formik={formik} />
      <Row>
        <FieldCheckbox field={fieldInfo[ID_ETC]} formik={formik} className="mr-2" />
        <Input value={etc} onChange={handleChangeEtc} disabled={!formik.values[ID_ETC]} className="w-full" />
      </Row>
      <FieldTextarea field={fieldInfo['message']} formik={formik} />
      <FieldTextarea field={fieldInfo['howToHearUs']} formik={formik} />
      <Center>
        <ButtonDefault type="submit">Submit</ButtonDefault>
      </Center>
    </form>
  );
}
