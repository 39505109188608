import {map} from 'ramda';

import FormikField from "./FormikField";
import Input from "./Input";
import RadioButton from './RadioButton';
import Textarea from './Textarea';
import Dropdown from './Dropdown';
import Checkbox from './Checkbox';

const FieldContainer = ({children}) => (
  <div className="mb-3">{children}</div>
);

const FieldLabel = ({field}) => <div className='mb-2'>{field.label ? field.label : <label>&nbsp;</label>}</div>;

export const Field = ({field, formik, render, ...otherProps}) => !field ? null : (
  <FieldContainer>
    <FieldLabel field={field} />
    <FormikField id={field.id} name={field.name} placeholder={field.placeholder} className="w-full" formik={formik} render={render} {...otherProps} />
  </FieldContainer>
);

export const FieldInput = (props) => <Field {...props} render={Input} />;
export const FieldTextarea = (props) => <Field {...props} render={Textarea} />;
export const FieldDropdown = (props) => <Field {...props} render={Dropdown} />;
export const FieldRadioButton = ({field, formik, ...otherProps}) => (
  <FieldContainer>
    <FieldLabel field={field} />
    <div className="flex flex-wrap whitespace-nowrap">
    {
      map(d => 
        <FormikField 
          key={d.id} 
          id={d.id} 
          name={field.name} 
          value={d.value} 
          label={d.label} 
          formik={formik} 
          defaultChecked={d.value === formik.values[field.name]}
          render={RadioButton} 
          {...otherProps} 
          className="mr-5 mb-2"
        />, 
        field.items)
    }
    </div>
  </FieldContainer>
);
export const FieldCheckbox = ({field, formik, ...otherProps}) => (
  <FieldContainer>
    <FormikField id={field.id} name={field.name} label={field.label} className="w-full" formik={formik} render={Checkbox} {...otherProps} />
  </FieldContainer>
);
export const FieldCheckboxList = ({field, formik, ...otherProps}) => (
  <FieldContainer>
    <FieldLabel field={field} />
    <div className="flex flex-wrap whitespace-nowrap">
    {
      map(d => 
        <FormikField 
          key={d.label} 
          id={d.id} 
          name={field.name} 
          value={d.value} 
          label={d.label} 
          formik={formik} 
          defaultChecked={d.value === formik.values[field.name]}
          render={Checkbox} 
          {...otherProps} 
          className="mr-5 mb-2"
        />, 
        field.items)
    }
    </div>
  </FieldContainer>
);
export const FieldInputList = ({fields, formik}) => fields.map(d => <FieldInput key={d.id} field={d} formik={formik} />);